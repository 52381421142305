import React, { useEffect, useState } from 'react';
import { tss } from 'tss-react';

import {
  Card,
  Chip,
  Modal,
  ModalClose,
  ModalDialog,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/joy';
import {
  AutoAwesome,
  Toll,
  ContactMail,
  Quiz,
  EditNote,
  DriveFileRenameOutline,
  LinkedIn as LinkedInLogo,
  Extension,
  Verified,
} from '@mui/icons-material';
import { assetLink } from 'src/utils';
import {
  EnrichColType,
  GetListResponse,
  Type24EnumsEnrichColType,
  Type24EnumsEnrichScrapeDataType,
} from 'src/generated/api';
import ScrapeWebsite, { ScrapeWebsiteDefaultVals } from './ScrapeWebsite';
import ContactInfo from './ContactInfo';
import PersonalizedMessage from './PersonalizedMessage';
import SimplifiedName from './SimplifiedName';
import Pitchbook from './Pitchbook';
import LinkedIn from './LinkedIn';
import IsBootstrapped from './IsBootstrapped';
import StrategicFit from './StrategicFit';
import IsValid from './IsValid';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    enrichmentModal: {
      padding: theme.spacing(2),
      overflowY: 'auto',
      width: 600,
    },
    enrichmentTypePicker: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      padding: theme.spacing(4),
    },
    card: {
      padding: theme.spacing(1),
      gap: theme.spacing(1),
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.neutral.plainHoverBg,
      },
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginBottom: theme.spacing(1),
      gap: theme.spacing(1),
    },
    enrichmentHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    creditChip: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    creditIcon: {
      width: 20,
      height: 20,
    },
    cardHeaderRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    cardHeaderIcon: {
      width: 20,
      height: 20,
      marginRight: theme.spacing(1),
    },
  }));

type Props = {
  enrichment: GetListResponse['enrichmentColumns'][number] | null;
  listId: string;
  onClose: (modified?: {
    id: string;
    type: EnrichColType;
  }) => void;
};

export default function EnrichmentModal({
  enrichment,
  listId,
  onClose,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const [enrichmentType, setEnrichmentType] = useState<EnrichColType | null | 'strategic-fit'>(null);
  const [scrapeWebsiteDefaults, setScrapeWebsiteDefaults] =
    useState<ScrapeWebsiteDefaultVals | null>(null);
  useEffect(() => {
    if (enrichment) {
      setEnrichmentType(enrichment.type);
    }
  }, [enrichment]);
  return (
    <Modal
      open
      onClose={(e, reason) => {
        if (reason === 'closeClick') {
          onClose();
        }
      }}
    >
      <ModalDialog className={classes.enrichmentModal}>
        <ModalClose />
        {
          enrichment == null && enrichmentType == null &&
          <div className={classes.enrichmentTypePicker}>
            <Typography level='body-lg' className={classes.header}>
              <AutoAwesome />
              <b>
                Select An Enrichment
              </b>
            </Typography>
            <Card
              className={classes.card}
              onClick={() => setEnrichmentType(Type24EnumsEnrichColType.SCRAPE)}
            >
              <div className={classes.enrichmentHeader}>
                <Typography level='body-md' className={classes.cardHeaderRow}>
                  <Quiz className={classes.cardHeaderIcon} />
                  <b>
                    Ask AI
                  </b>
                </Typography>
                <Tooltip title='Costs 1 credit per successful row'>
                  <Chip color='success' className={classes.creditChip}>
                    <Toll className={classes.creditIcon} />
                    1
                  </Chip>
                </Tooltip>
              </div>
              <Typography level='body-sm'>
                Use an AI agent to crawl a business' website to answer a question
                about them, or to extract key information
              </Typography>
            </Card>
            <Card
              className={classes.card}
              onClick={() => setEnrichmentType(Type24EnumsEnrichColType.CONTACT_INFO)}
            >
              <div className={classes.enrichmentHeader}>
                <Typography level='body-md' className={classes.cardHeaderRow}>
                  <ContactMail className={classes.cardHeaderIcon} />
                  <b>
                    Find Contact Info
                  </b>
                </Typography>
                <Tooltip title='Costs 1 credit per successful row'>
                  <Chip color='success' className={classes.creditChip}>
                    <Toll className={classes.creditIcon} />
                    1
                  </Chip>
                </Tooltip>
              </div>
              <Typography level='body-sm'>
                Identify a decision maker in businesses by job title and search for
                their name, email address, linkedin, and mobile number across our partner databases
              </Typography>
            </Card>
            <Card
              className={classes.card}
              onClick={() => setEnrichmentType(Type24EnumsEnrichColType.PERSONALIZED_MESSAGE)}
            >
              <div className={classes.enrichmentHeader}>
                <Typography level='body-md' className={classes.creditChip}>
                  <EditNote className={classes.cardHeaderIcon} />
                  <b>
                    Write a Customized Message
                  </b>
                </Typography>
                <Tooltip title='Costs 1 credit per successful row'>
                  <Chip color='success' className={classes.creditChip}>
                    <Toll className={classes.creditIcon} />
                    1
                  </Chip>
                </Tooltip>
              </div>
              <Typography level='body-sm'>
                Craft personalized copy based on the company's description, products,
                services, and end markets served
              </Typography>
            </Card>
            <Card
              className={classes.card}
              onClick={() => setEnrichmentType(Type24EnumsEnrichColType.SIMPLIFIED_NAME)}
            >
              <div className={classes.enrichmentHeader}>
                <Typography level='body-md' className={classes.creditChip}>
                  <DriveFileRenameOutline className={classes.cardHeaderIcon} />
                  <b>
                    Simplify Name
                  </b>
                </Typography>
                <Tooltip title='Costs 1 credit per successful row'>
                  <Chip color='success' className={classes.creditChip}>
                    <Toll className={classes.creditIcon} />
                    1
                  </Chip>
                </Tooltip>
              </div>
              <Typography level='body-sm'>
                Use AI to simplify the official name of the business, so your templated
                emails sounds more human.
              </Typography>
            </Card>
            <Card
              className={classes.card}
              onClick={() => setEnrichmentType(Type24EnumsEnrichColType.IS_BOOTSTRAPPED)}
            >
              <div className={classes.enrichmentHeader}>
                <Typography level='body-md' className={classes.creditChip}>
                  <img
                    src={assetLink('pitchbook.png')}
                    className={classes.cardHeaderIcon}
                  />
                  <b>
                    Bootstrapped
                  </b>
                </Typography>
                <Tooltip title='Costs 1 credit per successful row'>
                  <Chip color='success' className={classes.creditChip}>
                    <Toll className={classes.creditIcon} />
                    1
                  </Chip>
                </Tooltip>
              </div>
              <Typography level='body-sm'>
                Checks PitchBook to see if this business is funded or bootstrapped.
              </Typography>
            </Card>
            <Card
              className={classes.card}
              onClick={() => setEnrichmentType(Type24EnumsEnrichColType.PITCHBOOK)}
            >
              <div className={classes.enrichmentHeader}>
                <Typography level='body-md' className={classes.creditChip}>
                  <img
                    src={assetLink('pitchbook.png')}
                    className={classes.cardHeaderIcon}
                  />
                  <b>
                    Pitchbook
                  </b>
                </Typography>
                <Tooltip title='Costs 1 credit per successful row'>
                  <Chip color='success' className={classes.creditChip}>
                    <Toll className={classes.creditIcon} />
                    1
                  </Chip>
                </Tooltip>
              </div>
              <Typography level='body-sm'>
                Find a business' Pitchbook profile and scrape key info
              </Typography>
            </Card>
            <Card
              className={classes.card}
              onClick={() => setEnrichmentType(Type24EnumsEnrichColType.LINKEDIN)}
            >
              <div className={classes.enrichmentHeader}>
                <Typography level='body-md' className={classes.creditChip}>
                  <LinkedInLogo
                    className={classes.cardHeaderIcon}
                  />
                  <b>
                    LinkedIn
                  </b>
                </Typography>
                <Tooltip title='Costs 1 credit per successful row'>
                  <Chip color='success' className={classes.creditChip}>
                    <Toll className={classes.creditIcon} />
                    1
                  </Chip>
                </Tooltip>
              </div>
              <Typography level='body-sm'>
                Find a business' LinkedIn profile and scrape key info
              </Typography>
            </Card>
            <Card
              className={classes.card}
              onClick={() => setEnrichmentType('strategic-fit')}
            >
              <div className={classes.enrichmentHeader}>
                <Typography level='body-md' className={classes.cardHeaderRow}>
                  <Extension className={classes.cardHeaderIcon} />
                  <b>
                    Strategic Fit
                  </b>
                </Typography>
                <Tooltip title='Costs 1 credit per successful row'>
                  <Chip color='success' className={classes.creditChip}>
                    <Toll className={classes.creditIcon} />
                    1
                  </Chip>
                </Tooltip>
              </div>
              <Typography level='body-sm'>
                Use AI to generate a criteria for determining if a business is a strategic fit
              </Typography>
            </Card>
            <Card
              className={classes.card}
              onClick={() => setEnrichmentType(Type24EnumsEnrichColType.IS_VALID)}
            >
              <div className={classes.enrichmentHeader}>
                <Typography level='body-md' className={classes.creditChip}>
                  <Verified className={classes.cardHeaderIcon} />
                  <b>
                    Is Still Active
                  </b>
                </Typography>
                <Tooltip title='Costs 1 credit per successful row'>
                  <Chip color='success' className={classes.creditChip}>
                    <Toll className={classes.creditIcon} />
                    1
                  </Chip>
                </Tooltip>
              </div>
              <Typography level='body-sm'>
                Check if this business is still active and in operation
              </Typography>
            </Card>
          </div>
        }
        {
          enrichmentType === Type24EnumsEnrichColType.SCRAPE &&
          <ScrapeWebsite
            onBack={() => setEnrichmentType(null)}
            enrichment={enrichment}
            listId={listId}
            onClose={onClose}
            defaultVals={scrapeWebsiteDefaults ?? undefined}
          />
        }
        {
          enrichmentType === Type24EnumsEnrichColType.CONTACT_INFO &&
          <ContactInfo
            onBack={() => setEnrichmentType(null)}
            enrichment={enrichment}
            listId={listId}
            onClose={onClose}
          />
        }
        {
          enrichmentType === Type24EnumsEnrichColType.PERSONALIZED_MESSAGE &&
          <PersonalizedMessage
            onBack={() => setEnrichmentType(null)}
            enrichment={enrichment}
            listId={listId}
            onClose={onClose}
          />
        }
        {
          enrichmentType === Type24EnumsEnrichColType.SIMPLIFIED_NAME &&
          <SimplifiedName
            onBack={() => setEnrichmentType(null)}
            enrichment={enrichment}
            listId={listId}
            onClose={onClose}
          />
        }
        {
          enrichmentType === Type24EnumsEnrichColType.IS_BOOTSTRAPPED &&
          <IsBootstrapped
            onBack={() => setEnrichmentType(null)}
            enrichment={enrichment}
            listId={listId}
            onClose={onClose}
          />
        }
        {
          enrichmentType === Type24EnumsEnrichColType.PITCHBOOK &&
          <Pitchbook
            onBack={() => setEnrichmentType(null)}
            enrichment={enrichment}
            listId={listId}
            onClose={onClose}
          />
        }
        {
          enrichmentType === Type24EnumsEnrichColType.LINKEDIN &&
          <LinkedIn
            onBack={() => setEnrichmentType(null)}
            enrichment={enrichment}
            listId={listId}
            onClose={onClose}
          />
        }
        {
          enrichmentType === Type24EnumsEnrichColType.IS_VALID &&
          <IsValid
            onBack={() => setEnrichmentType(null)}
            enrichment={enrichment}
            listId={listId}
            onClose={onClose}
          />
        }
        {
          enrichmentType === 'strategic-fit' &&
          <StrategicFit
            onBack={() => setEnrichmentType(null)}
            onNext={(prompt, high, mid, low) => {
              setScrapeWebsiteDefaults({
                name: 'Strategic Fit',
                prompt,
                dataType: Type24EnumsEnrichScrapeDataType.CLASSIFICATION,
                dataTypeExtra: {
                  options: [{
                    name: 'High',
                    description: high,
                  }, {
                    name: 'Medium',
                    description: mid,
                  }, {
                    name: 'Low',
                    description: low,
                  }],
                },
              });
              setEnrichmentType(Type24EnumsEnrichColType.SCRAPE);
            }}
          />
        }
      </ModalDialog>
    </Modal>
  );
}
