import React, { useEffect, useState, useCallback } from 'react';
import { tss } from 'tss-react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Chip,
  List,
  ListItem,
  ListItemButton,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import LoadingContainer from 'src/components/LoadingContainer';
import { getAPI } from 'src/api';
import { GetProjectsResponse } from 'src/generated/api';
import {
  Person,
} from '@mui/icons-material';
import CreateClientModal from './Create';

const useStyles = tss
  .withParams<{ theme: Theme }>()
  .create(({ theme }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      marginTop: theme.spacing(4),
    },
    contents: {
      maxWidth: 650,
      width: '100%',
    },
    field: {
      width: 300,
      marginBottom: theme.spacing(3),
    },
    button: {
      margin: theme.spacing(3),
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      width: '100%',
    },
    createButton: {
      marginLeft: 'auto',
    },
    lists: {
      marginTop: theme.spacing(4),
      boxShadow: theme.shadow.lg,
      overflowY: 'auto',
      maxHeight: '70vh',
    },
    listItem: {
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    openCol: {
      width: 100,
    },
    openBtn: {
      marginLeft: 'auto',
    },
    icon: {
      width: 32,
      height: 32,
    },
    headerLeft: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    title: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    listItemRight: {
      marginLeft: 'auto',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    emptyState: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: theme.shadow.lg,
    },
    optionsMenu: {
      padding: theme.spacing(1),
      width: 24,
      height: 24,
    },
    optionsIcon: {
      width: 18,
      height: 18,
    },
  }));

export default function Clients() {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const navigate = useNavigate();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [projects, setProjects] = useState<GetProjectsResponse['projects'] | null>();
  const api = getAPI();
  const fetch = useCallback(async () => {
    const res = await api.clients.getProjects();
    setProjects(res.data.projects);
  }, [api]);
  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <div className={classes.container}>
      <LoadingContainer isLoading={!projects}>
        <div className={classes.contents}>
          <div className={classes.header}>
            <div className={classes.headerLeft}>
              <Typography level="h3" className={classes.title}>
                <Person className={classes.icon} />
                Clients
              </Typography>
            </div>
            <Button
              className={classes.createButton}
              color='success'
              onClick={() => setShowCreateModal(true)}
            >
              Create
            </Button>
          </div>
          {
            projects?.length === 0 ?
              <Card className={classes.emptyState} variant='plain'>
                <Typography level='h4'>
                  You have no clients
                </Typography>
              </Card> :
              <List className={classes.lists}>
                {
                  projects?.map((p) => (
                    <ListItem key={p.id}>
                      <ListItemButton
                        className={classes.listItem}
                        onClick={() => navigate(`/clients/${p.id}`)}
                      >
                        <Typography level='h4'>
                          {p.name || p.domain}
                        </Typography>
                        {
                          !p.intakeGenerated &&
                          <Chip
                            size='lg'
                            color='neutral'
                          >
                            Intake Not Started
                          </Chip>
                        }
                        {
                          p.intakeGenerated && !p.intakeCompleted &&
                          <Chip
                            size='lg'
                            color='primary'
                          >
                            Intake
                          </Chip>
                        }
                        {
                          p.intakeCompleted &&
                          <Chip
                            size='lg'
                            color='success'
                          >
                            Intake Complete
                          </Chip>
                        }
                      </ListItemButton>
                    </ListItem>
                  ))
                }
              </List>
          }
        </div>
      </LoadingContainer>
      {
        showCreateModal &&
        <CreateClientModal
          onClose={() => {
            setShowCreateModal(false);
          }}
        />
      }
    </div>
  );
}
